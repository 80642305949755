/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const LangTiles = ({ handleTileClick }) => {
  return (
    <div className="lang-tiles">
      <div
        style={{
          backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/7/7d/National_Flag_of_Poland.png)',
        }}
        onClick={() => handleTileClick('pl')}
        className="lang-tile"
      >
        <span>PL</span>
      </div>
      <div
        style={{
          backgroundImage:
            'url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png)',
        }}
        onClick={() => handleTileClick('eng')}
        className="lang-tile"
      >
        <span>ENG</span>
      </div>
    </div>
  );
};

export default LangTiles;
