/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link } from 'gatsby';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import Spinner from '../components/layout/Spinner';
import LangTiles from '../components/registration/LangTiles';
import RegistrationForm from '../components/registration/RegistrationForm';
import bg from '../images/festival/bg.jpg';

const Registration = () => {
  const [language, setLanguage] = useState();
  const [isLoading, setIsLoading] = useState();
  const [products, setProducts] = useState();

  const [fetchData] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIsLoading(true);
      setProducts(data.registrationFormConfigs.nodes);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const spinnerWrapper = (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 9,
      }}
    >
      <Spinner />
    </div>
  );

  return (
    <Layout isFestival>
      <SEO title="Festiwal - harmonogram" />
      <div className="festival full">
        <img src={bg} alt="background" />
        <div className="color-layer" />
        {isLoading ? (
          spinnerWrapper
        ) : (
          <div className="registration">
            <Link to="/festival">&larr; Powrót do strony festiwalu</Link>
            {!language && <LangTiles handleTileClick={(lang) => setLanguage(lang)} />}
            {language && (
              <RegistrationForm products={products} setLoading={(value) => setIsLoading(value)} language={language} />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Registration;

const GET_PRODUCTS = gql`
  query GetProducts {
    registrationFormConfigs {
      nodes {
        forms_data {
          type
          namePl
          nameEng
          startingDate
          endDate
          pricePl
          priceEng
          isSolo
        }
      }
    }
  }
`;
